<template>
  <div id="freelancer-body">
    <div class="partial-loader" v-if="loading">
      <loader/>
    </div>

    <h1 class="become-freelancer-header">Freelancer <span>Ol!</span></h1>
    <h3 class="hello-text">Merhaba, <span>{{user.firstname}}</span>. 👋</h3>

    <table class="become-freelancer-table">
      <!--Profil Fotoğraf-->
      <tr>
        <td class="section-info">
          <p class="section-header">Profil <span>Fotoğrafı</span></p>
          <p class="section-descr">Seni en iyi yansıttığını düşündüğün profil resmini ekle! Böylelikle müşterilerin tam olarak kiminle çalışacaklarını bilirler.</p>
        </td>
        <td class="section-action">
          <div class="birthday-select-form">
            <img :src="user.avatar_url" alt="avatar" class="freelancer-avatar-img"/>
            <div class="avatar-add" @click="setImage()">
              <input id="files" style="display: none;" type="file" @change="onFileChange">
              <img src="https://gcdn.bionluk.com/site/c/ic-change.svg" onload="SVGInject(this)" class="avatar-add-img">
              <p class="avatar-add-text">{{user.avatar_url && user.avatar_url.includes('avatar_empty') ? 'Ekle' : 'Değiştir'}}</p>
            </div>
          </div>

        </td>
      </tr>

      <!--Doğum Tarihin-->
      <tr>
        <td class="section-info">
          <p class="section-header">Doğum <span>Tarihin</span></p>
          <p class="section-descr">Bu bilgiyi kesinlikle profilinde göstermeyeceğiz fakat Bionluk’ta freelancer olmak için <span class="bold">18 yaşından büyük olman</span> gerekir. 😊</p>
        </td>
        <td class="section-action">
          <div class="birthday-select-form">
            <custom-select label="Gün"
                           id="day"
                           :options="dayArr"
                           v-model="day"
                           :valid="!!day ? true : null"
                           labelPosition="center"
                           style="width: 80px; margin-right: 20px;"/>

            <custom-select label="Ay"
                           id="month"
                           :options="monthArr"
                           v-model="month"
                           :valid="!!month ? true : null"
                           labelPosition="center"
                           style="width: 80px; margin-right: 20px;"/>

            <custom-select label="Yıl"
                           id="year"
                           :options="yearArr"
                           v-model="year"
                           :valid="!!year ? true : null"
                           labelPosition="center"
                           style="width: 80px;"/>
          </div>

        </td>
      </tr>

      <!--Uzmanlık Alanın-->
      <tr>
        <td class="section-info">
          <p class="section-header">Uzmanlık <span>Alanın</span></p>
          <p class="section-descr">Uzmanlık kategorini ve uzmanı olduğun alanı belirtmen çok önemli. Eğer üşengeç biriysen, kendinden daha sonra da bahsedebilirsin.</p>
        </td>
        <td class="section-action">
          <custom-select label="Uzmanlık Kategorisi"
                         id="professionCategory"
                         :options="professionCategoryArr"
                         :customOption="{name: 'name', value:'value'}"
                         v-model="professionCategory"
                         :valid="!!professionCategory ? true : null"
                         style="width: 393px; margin-bottom: 42px;"/>

          <custom-input label="Başlık"
                        place-holder="Örn: Tasarımcı"
                        inputType="textArea"
                        v-model="title"
                        :valid="!!title ? true : null"
                        maxlength="30"
                        style="margin-bottom: 42px; height: 45px; width: 393px;"/>
          <custom-input label="Hakkında"
                        placeHolder="Hadi bize biraz kendinden bahset… 🤓"
                        inputType="textArea"
                        v-model="brief"
                        :valid="!!brief ? true : null"
                        maxlength="600"
                        style="width:593px; height: 200px; margin-top: 55px;"/>

        </td>
      </tr>

      <!--Uzmanı Olduğun Alanlar & Araçlar-->
      <tr>
        <td class="section-info">
          <p class="section-header">Uzmanı Olduğun <span>Alanlar & Araçlar </span></p>
          <p class="section-descr">Uzmanı olduğun alan ve araçları eklemen alıcıların tercihte bulunmaları ve daha kolay teklif alman için faydalı olacaktır. <span class="green">[En az 1, en fazla 6 adet yetenek ekleyebilirsin.]</span></p>
        </td>
        <td class="section-action">
          <div>
            <div style="display: flex; align-items: center; margin-top: 16px;">
              <custom-select label="Yetenekler"
                             placeHolder="Örnek: Adobe XD veya Grafik Tasarımı"
                             id="skills"
                             inputType="search"
                             :disabled="disableSkillInput"
                             :options="skillSearchResults"
                             :exactMatchExists="exactSkillExists"
                             :customOption="{name: 'name', value:'id'}"
                             :suggestion-enabled="true"
                             v-model="skillSearchTerm"
                             @change="selectBoxOnChange"
                             @focus="openSkillTooltip = false"
                             style="width: 393px;"/>
              <div class="hint-body">
                <img src="https://gcdn.bionluk.com/site/icon/tansel_info_gray.svg" onload="SVGInject(this)" class="hint-skill-icon"/>
                <p>Bazı <span>İpuçları</span> 😉</p>
                <div class="tooltiptext" :style="openSkillTooltip ? 'visibility: visible;' : ''">
                  <help-box
                    description="<ul style='margin-left:20px;list-style:disc'><li>En fazla <span style='font-weight: 800;'>6 adet</span> yetenek ekleyebilirsin.</li><li style='margin-top:20px'>Eğer yeni bir öneride bulunursan, uygunluğu incelenecek ve daha sonra yayınlanacak.</li></ul>"></help-box>

                </div>

              </div>

            </div>

            <div class="editable-skills-body">
              <div v-for="skill in skills" :key="skill.id">
                <div class="suggestion-skill-item" v-if="skill.isSuggestion"
                     data-balloon="Öneride bulunduğun için teşekkürler. 🤓 Önerdiğin yetenek ilk fırsatta incelenecek ve eğer kabul görürse profil sayfanda görüntülenmeye başlayacak."
                     data-balloon-pos="up"
                     data-balloon-length="medium" @click="deleteSkill(skill)">
                  <img src="https://gcdn.bionluk.com/site/c/ic-timer.svg" onload="SVGInject(this)" class="suggestion-skill-icon">
                  {{skill.name}} <span>[Senin Önerin]</span>
                  <img src="https://gcdn.bionluk.com/site/icon/ic_close_black.svg" onload="SVGInject(this)" class="delete-skill-icon"/>
                </div>

                <div class="editable-skill-item" @click="deleteSkill(skill)" v-else>
                  {{skill.name}}
                  <img src="https://gcdn.bionluk.com/site/icon/ic_close_black.svg" onload="SVGInject(this)" class="delete-skill-icon"/>
                </div>
              </div>
            </div>

          </div>
        </td>
      </tr>
    </table>

    <custom-button button-type="green" @click="finishButton()" style="width: 500px; margin: auto;">Ödemeyi Tamamla (69.90 TL) ve Freelancer Profilimi Aktif Et</custom-button>
  </div>
</template>

<script>
  import CustomButton from '../../../components/CustomButton'
  import CustomInput from '../../../components/CustomInput'
  import CustomSelect from '../../../components/CustomSelect'
  import helpBox from "../../../components/HelpBox"
  import Fuse from 'fuse.js'

  export default {
    name: "src-pages-body-onboarding-freelancer-v4",
    components: {
      CustomButton,
      CustomInput,
      CustomSelect,
      helpBox
    },

    data() {
      return {
        loading: false,
        dayArr: [],
        day: null,
        monthArr: [],
        month: null,
        yearArr: [],
        year: null,
        professionCategoryArr: [
          {
            value: "Consultant",
            name: "Danışman"
          },
          {
            value: "Data Scientist",
            name: "Data Scientist"
          },
          {
            value: "Developer",
            name: "Yazılım Uzmanı"
          },
          {
            value: "Designer",
            name: "Tasarımcı"
          },
          {
            value: "Influencer",
            name: "Influencer"
          },
          {
            value: "Marketer",
            name: "Pazarlama Uzmanı"
          },
          {
            value: "Musician",
            name: "Müzisyen"
          },
          {
            value: "Translator",
            name: "Çevirmen"
          },
          {
            value: "Video Maker",
            name: "Video/Prodüksiyon Uzmanı"
          },
          {
            value: "Virtual Asistant",
            name: "Sanal Asistan"
          },
          {
            value: "Voiceover Artist",
            name: "Seslendirmen"
          },
          {
            value: "Writer",
            name: "Yazar"
          },
        ],
        professionCategory: '',
        title: '',
        brief: '',
        allSkills: [],
        skills: [],
        skillSearchTerm: '',
        skillSearchResults: [],
        disableSkillInput: false,
        openSkillTooltip: true,
        exactSkillExists: 0,
        fuse: null
      }
    },
    methods: {
      setInitialValues() {
        this.getAllSkills();
        this.title = this.user.info.title && this.user.info.title.length ? this.user.info.title : '';
        this.brief = this.user.info.brief && this.user.info.brief.length ? this.user.info.brief : '';
      },
      getAllSkills() {
        this.loading = true
        this.api.general.getAllSkills()
          .then(({ data: resultSkills }) => {
            this.allSkills = this.Helper.reject(resultSkills.data, {parent_id: null})
            this.allSkills.forEach((skill) => {
              skill.name = skill.name.tr_TR
            })
            const options = { keys: ['name'], includeMatches: true, ignoreLocation: true, threshold: 0.4, findAllMatches: true }
            const index = Fuse.createIndex(options.keys, this.allSkills)
            this.fuse = new Fuse(this.allSkills, options, index)
          })
          .catch(() => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
          .finally(() => {
            this.loading = false
          })
      },
      onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        const validImageTypes = ['image/jpeg', 'image/png'];
        if (!validImageTypes.includes(files[0]['type'])) {
          this.$toasted.global.errorToast({description: "Lütfen geçerli bir görsel dosyası ekle. (.jpg, .jpeg veya .png)"});
          document.getElementById("files").value = "";
        } else {
          this.$store.commit(this.types.ACTIVE_MODAL, {
            modalType: this.Modals.CROPPER,
            info: {file: files[0], canvasPixel: {width: 400, height: 400}, divider: 1, viewMode: 1, fileType: "profileImage"}
          });
          document.getElementById("files").value = "";
        }
      },
      setImage() {
        document.getElementById('files').click();
      },
      getCroppedImage(payload) {
        this.uploadResponseStatus = "started";
        this.timeStamp = +new Date();
        this.uploadFileToGoogle(payload.file, this.Constants.UPLOAD_TYPES.AVATAR, 'onboarding', this.timeStamp)
          .then(result => {
            this.$store.state.user.avatar_url = this.Constants.CDN_UPLOADS_G + result.uploadName;
            this.uploadResponseStatus = null;
	          this.Helper.trackEvents.customEvent("uploaded_avatar", {version:'v1',whereFrom:this.$store.state.componentMap.page,clickedFrom:'become a freelancer'});

          })
          .catch(err => {
            this.$toasted.global.infoToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.uploadResponseStatus = null;
          });
      },
      finishButton() {
        this.saveFreelancerInformation(1)
      },
      saveFreelancerInformation(isControl) {
        this.loading = true;

        let skills = JSON.stringify(this.skills);
        let birthDate = null;
        if (this.day && this.month && this.year) {
          birthDate = this.day + '-' + this.month + '-' + this.year;
        }
        this.api.seller.saveFreelancerInformationV5(this.professionCategory, this.brief, this.title, skills, birthDate, isControl,  this.$Progress,)
          .then(({data}) => {
            let result = data;
            if (result.success) {

              if (result.data && result.data.ok && result.data.ok === 'go') {
                isControl = 0;
              }
              if (isControl) {
                const data = {
                  data: {
                    type: "item",
                    total: 69.90,
                    itemId: 1,
                    cards: result.data.cards
                  }
                };
                this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.CHECKOUT, info: {checkOutData: data, isSinglePay: true}});
              } else {
                this.$store.state.user.seller.isSeller = true;
                this.$store.state.user.info.title = this.title;
                this.$store.state.user.info.brief = this.brief;
                this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.WELCOME_SELLER});
                this.setRedirectURLToRouterMap("/become_a_freelancer/", "/bugun");
              }
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            this.loading = false;


          })
          .catch(err => {
            //console.log(err)
            this.loading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      selectBoxOnChange(e) {
        const skills = [...this.skills];
        if (e.isSuggestion) skills.push({name: e.value, isSuggestion: true, id: +new Date()});
        else skills.push({name: e.name, isSuggestion: false, id: e.id});
        this.skills = [...skills];
        this.skillSearchTerm = '';
      },
      deleteSkill(skill) {
        this.skills = this.skills.filter(({ id }) => id !== skill.id)
      },
      initializeDate() {
        for (let i = 1; i <= 31; i++) {
          let day = '';
          if (i < 10) day = '0' + i.toString();
          else day = i.toString();
          this.dayArr.push(day)
        }


        for (let i = 1; i < 13; i++) {
          let month = '';
          if (i < 10) month = '0' + i.toString();
          else month = i.toString();
          this.monthArr.push(month)
        }

        for (let i = 1930; i < new Date().getFullYear() - 17; i++) {
          const year = i.toString();
          this.yearArr.push(year)
        }
      },
      isLeapYear(year) {
        return Number(year) % 4 === 0;

      }
    },
    watch: {
      skills(newVal) {
        this.disableSkillInput = newVal && newVal.length >= 6;
      },
      skillSearchTerm(newValue) {
        if (newValue.length < 20) {
          this.skillSearchResults = this.fuse.search(newValue.trim()).map(result => result.item)
          this.exactSkillExists = !!this.allSkills.find(skill => skill.name.toLocaleLowerCase('tr') === newValue.trim().toLocaleLowerCase('tr'))
        } else {
          this.skillSearchResults = []
        }
      },
      year(newVal) {
        if (newVal) {
          this.dayArr = [];
          let days = 31;
          const month = Number(this.month);
          const year = Number(newVal);
          if (month === 1 || month === 3 || month === 5 || month === 7
            || month === 8 || month === 10 || month === 12)
            days = 31;
          else if (month === 4 || month === 6 || month === 9
            || month === 11)
            days = 30;

          if (month === 2 && this.isLeapYear(year)) {
            days = 29;
          } else if (month === 2) {
            days = 28;
          }

          for (let i = 1; i <= days; i++) {
            let day = '';
            if (i < 10) day = '0' + i.toString();
            else day = i.toString();
            this.dayArr.push(day)
          }
          if (this.day && Number(this.day) > Number(this.dayArr[this.dayArr.length - 1]))
            this.day = this.dayArr[this.dayArr.length - 1];
        }
      },
      month(newVal) {
        if (newVal) {
          this.dayArr = [];
          let days = 31;
          const month = Number(newVal);
          const year = Number(this.year);
          if (month === 1 || month === 3 || month === 5 || month === 7
            || month === 8 || month === 10 || month === 12)
            days = 31;
          else if (month === 4 || month === 6 || month === 9
            || month === 11)
            days = 30;

          if (month === 2 && this.isLeapYear(year)) {
            days = 29;
          } else if (month === 2) {
            days = 28;
          }

          for (let i = 1; i <= days; i++) {
            let day = '';
            if (i < 10) day = '0' + i.toString();
            else day = i.toString();
            this.dayArr.push(day)
          }
          if (this.day && Number(this.day) > Number(this.dayArr[this.dayArr.length - 1]))
            this.day = this.dayArr[this.dayArr.length - 1];
        }
      }
    },
    created() {
     // this.Helper.initLogrocketManual({freelancerPage:true})
      this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.PRICING});
      this.setInitialValues();
      this.EventBus.$on('cropperImageFile', payload => {
        if (payload.fileType === "profileImage") {
          this.getCroppedImage(payload);
        }
      });
      this.EventBus.$on('isSinglePayed', (payload) => {
        if (payload.isSinglePayed) {
          this.saveFreelancerInformation(0);
        } else {
          this.$toasted.global.errorToast({description: 'Ödeme Başarısız Oldu'});
        }
      });

      this.initializeDate();
    },
    beforeDestroy() {
      this.EventBus.$off("cropperImageFile");
      this.EventBus.$off('isSinglePayed');
    }
  }
</script>

<style scoped lang="scss">

  #freelancer-body {
    width: 960px;
    height: 100%;
    margin: auto;
    padding: 100px 0 50px 0;
    text-align: left;
    display: flex;
    flex-direction: column;
  }

  .become-freelancer-header {
    font-weight: 400;
    color: #4b4f52;
    font-size: 50px;
    margin: 0 0 10px;

    span {
      font-weight: 600;
    }
  }

  .become-freelancer-table {
    width: 100%;
  }

  .become-freelancer-table, th, tr, td {
    border: none;
  }

  td {
    vertical-align: top;
    padding-bottom: 120px;
  }

  .section-info {
    width: 360px;
  }

  .section-action {

  }

  .hello-text {
    font-weight: 400;
    color: #8b95a1;
    font-size: 24px;
    margin: 0 0 95px;

    span {
      font-weight: 600;
    }
  }

  .section-header {
    font-weight: 400;
    color: #4b4f52;
    font-size: 24px;
    margin: 0 0 10px;
    max-width: 260px;

    span {
      font-weight: 600;
    }
  }

  .section-descr {
    font-weight: 300;
    color: #5e6b79;
    line-height: 26px;
    font-size: 14px;
    max-width: 260px;

    .bold {
      font-weight: 800;
    }

    .green {
      color: #00a575;
      font-weight: 800;
    }
  }

  .freelancer-avatar-img {
    box-sizing: border-box;
    width: 120px;
    height: 120px;
    border: 2px solid #eaeaea;
    border-radius: 100%;
  }

  .avatar-add {
    display: flex;
    align-items: center;
    margin-left: 40px;
    cursor: pointer;
  }

  .avatar-add-img /deep/ {
    width: 14px;
    height: 14px;

    path {
      &:nth-child(2) {
        fill: #8b95a1;
      }
    }
  }

  .avatar-add-text {
    font-weight: 400;
    color: #8b95a1;
    font-size: 14px;
    margin-left: 5px;
  }

  .birthday-select-form {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  /*skills*/

  .skill-body {
    display: flex;
    flex-direction: row;
    align-items: center;
  }


  .skill-item-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  .skill-item {
    border: 1px solid #26ca9a;
    border-radius: 4px;
    padding: 7px 12px;
    color: #26ca9a;
    font-size: 13px;
    font-weight: 400;
    margin-left: 15px;
    margin-bottom: 10px;
  }

  .editable-skills-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .editable-skill-item {
    cursor: pointer;
    position: relative;
    background-color: #eaedf2;
    border-radius: 4px;
    padding: 5px 28px 5px 10px;
    line-height: 20px;
    font-size: 14px;
    color: #2d3640;
    font-weight: 400;
    margin-right: 25px;
    margin-top: 30px;
    border: 1px solid #eaedf2;


    &:hover {
      background-color: #26ca9a;
      color: #fff;
      border: 1px solid #26ca9a;

      .delete-skill-icon /deep/ {
        #ic_close_black {
          fill: #fff;
        }
      }

    }
  }

  .suggestion-skill-item {
    cursor: pointer;
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    padding: 5px 28px 5px 31px;
    line-height: 20px;
    font-size: 14px;
    color: #5e6b79;
    font-weight: 400;
    margin-right: 25px;
    margin-top: 30px;
    border: 1px solid #e5e5e5;

    &:after {
      pointer-events: none;
    }

    &:before {
      pointer-events: none;
    }
    span {
      color: #8b95a1;
    }
  }

  .delete-skill-icon /deep/ {
    width: 10px;
    height: 10px;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;

    #ic_close_black {
      fill: #bfc8d2;
    }
  }

  .suggestion-skill-icon /deep/ {
    width: 15px;
    height: 15px;
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    margin: auto;

    path {
      fill: #26ca9a;
    }
  }

  .hint-skill-icon /deep/ {
    width: 20px;
    height: 20px;

    g {
      fill: #5e6b79;
    }
  }

  .hint-body {
    position: relative;
    display: flex;
    align-items: center;
    cursor: help;
    margin-left: 25px;

    p {
      margin-left: 5px;
      font-weight: 400;
      font-size: 20px;
      color: #4b4f52;

      span {
        font-weight: 600;
      }
    }

    &:hover {
      .tooltiptext {
        visibility: visible;
      }
    }
  }

  .tooltiptext {
    display: block;
    visibility: hidden;
    position: absolute;
    width: 270px;
    background-color: #2d3640;
    color: #fff;
    text-align: center;
    padding: 5px 3px;
    border-radius: 6px;
    z-index: 1;
    left: 0;
    top: 36px;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  }

  .tooltiptext::after {
    content: " ";
    position: absolute;
    left: 15px;
    bottom: 100%;
    margin-left: -7px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent #2d3640 transparent;
  }

</style>
